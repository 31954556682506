import React from "react";
import "./App.css";

// This import loads the firebase namespace along with all its type information.
import * as firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/database";

import { Button } from "antd";

var firebaseConfig = {
  apiKey: "AIzaSyAjtg2AAgiwtHzDGFLp4o7EHU8O6SF0Wwg",
  authDomain: "vocalist-f3e0d.firebaseapp.com",
  databaseURL: "https://vocalist-f3e0d.firebaseio.com",
  projectId: "vocalist-f3e0d",
  storageBucket: "vocalist-f3e0d.appspot.com",
  messagingSenderId: "947458480280",
  appId: "1:947458480280:web:873288bbc821ab9fd018ed",
};

var app = firebase.initializeApp(firebaseConfig);

let user = JSON.parse(localStorage.getItem("user"));
let token = JSON.parse(localStorage.getItem("token"));

const App = () => {
  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  const login = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        localStorage.setItem(
          "token",
          JSON.stringify(result.credential.accessToken)
        );
        // The signed-in user info.
        localStorage.setItem("user", JSON.stringify(result.user));
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  return (
    <div>
      <Button onClick={() => login()}>Login</Button>
      <Button onClick={() => logout()}>Logout</Button>
    </div>
  );
};

export default App;
